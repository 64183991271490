import './App.css';
import {Home} from "./home.tsx"

function App() {
  return (
    <div className="App">
    <Home/>
    </div>
  );
}

export default App;
