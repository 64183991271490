import React, { useEffect, useRef, useState } from "react";
import "./homepage.css";
import logo1 from "../src/assets/card log.png";
import airport from "../src/assets/airport.jpg";
import oneWay from "../src/assets/one way.jpg";
import outstation from "../src/assets/outstation.jpg";
import mobile from "../src/assets/polo.png";
import blueBackground from "../src/assets/background.png";
import vehicle from "../src/assets/vehicle.jpg";
import vehicle1 from "../src/assets/vehicle1.jpg";
import vehicle2 from "../src/assets/vehicle2.jpg";
import chennai from "../src/assets/Chennai.jpg";
import pondicherry from "../src/assets/Pondicherry.jpg";
import madurai from "../src/assets/Madurai.jpg";
import bangalore from "../src/assets/Bangalore.jpg";
import coimbatore from "../src/assets/Coimbatore.jpg";
import trichy from "../src/assets/Trichy.jpg";
import home from "../src/assets/home.png";
import phone from "../src/assets/phone.png";
import mail from "../src/assets/mail.jpg";
import whatsappIcon from "../src/assets/wpIcon.png";
import phonelogo from "../src/assets/phoneDialer.png";
import safety from "../src/assets/safety.png";
import callSupport from "../src/assets/callSupport.png";
import driver from "../src/assets/driver.png";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Popover,
  Select,
  message,
  Spin,
} from "antd";
import greenTick from "../src/assets/tickGreen.png";
import axios from "axios";
const initialValues = {
  fullName: "",
  mobileNumber: "",
  emailId: "",
  pickupLocation: "",
  dropLocation: "",
  date: "",
  time: "",
  returnDate: "",
  rentFor: "",
};
export const Home = () => {
  const { Option } = Select;
  const [openWhatsapp, setOpenWhatsapp] = useState(true);
  const [openPhone, setOpenPhone] = useState(true);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const contactUsRef: any = useRef(null);
  const tariffRef: any = useRef(null);
  const packagesRef: any = useRef(null);
  const servicesRef: any = useRef(null);
  const homeRef: any = useRef(null);
  const [inputReq, setInputReq] = useState(initialValues);
  const [selectedType, setSelectedType] = useState("One Way");
  const [loading,setLoading]=useState(false)
  const [errorList, setErrorList] = useState({
    fullName: "",
    mobileNumber: "",
    emailId: "",
    pickupLocation: "",
    dropLocation: "",
    date: "",
    time: "",
    returnDate: "",
    rentFor: "",
  });
  const timeList = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];
  const rentForList = [
    "5 hrs 50 KM",
    "6 hrs 50 KM",
    "7 hrs 50 KM",
    "8 hrs 50 KM",
  ];
  useEffect(() => {
    setTimeout(() => {
      setOpenWhatsapp(false);
      setOpenPhone(false);
    }, 5000);

  }, []);
  const scrollToDiv = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = 0;
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    // debugger;
    // const node = document.getElementById(ref);
    // if (node) {
    //   node.scrollTop = 0;
    // }
  };
  const onClickNav = (ref) => {
    setDrawerVisibility(false);
    scrollToDiv(ref);
  };

  const bookNow = async () => {
    console.log({ inputReq });
    const errors = errorList;
    let isError = false;

    if (inputReq?.fullName == "") {
      // setErrorList({...errorList,fullName:"This field cannot be left empty."})
      errors.fullName = "This field cannot be left empty.";
      isError = true;
    }
    if (inputReq?.mobileNumber == "") {
      errors.mobileNumber = "This field cannot be left empty.";
      isError = true;
    }
    // if (inputReq?.emailId == "") {
    //   errors.emailId = "This field cannot be left empty.";
    //   isError = true;
    // }
    if (inputReq?.pickupLocation == "") {
      errors.pickupLocation = "This field cannot be left empty.";
      isError = true;
    }
    if (inputReq?.dropLocation == "") {
      errors.dropLocation = "This field cannot be left empty.";
      isError = true;
    }
    if (inputReq?.date == "") {
      errors.date = "This field cannot be left empty.";
      isError = true;
    }
    if (inputReq?.time == "") {
      errors.time = "This field cannot be left empty.";
      isError = true;
    }
    if (selectedType == "Round Trip" && inputReq?.returnDate == "") {
      errors.returnDate = "This field cannot be left empty.";
      isError = true;
    } else if (selectedType == "Rental" && inputReq?.rentFor == "") {
      errors.rentFor = "This field cannot be left empty.";
      isError = true;
    }
    if (isError) {
      setErrorList({ ...errorList, ...errors });
      return;
    } else {
      if (
        errorList.fullName != "" ||
        errorList?.mobileNumber != "" ||
        errorList?.emailId != ""
      ) {
        // message.error("Invalid input, please check and try again")
        return;
      }
      try {
      let payload;
      if(selectedType==="One Way"){
        payload={
          "tripMode": selectedType, 
          "name": inputReq?.fullName, 
          "mobileNumber": inputReq?.mobileNumber, 
          "pickupLocation": inputReq?.pickupLocation, 
          "dropLocation": inputReq?.dropLocation, 
          "startDate":inputReq?.date, 
          "startTime": inputReq?.time 
      }
      if(inputReq?.emailId){
        payload.email=inputReq?.emailId;
      }
      }else if(selectedType==="Round Trip"){
        payload={
          "tripMode": selectedType, 
          "name": inputReq?.fullName, 
          "mobileNumber": inputReq?.mobileNumber, 
          "pickupLocation": inputReq?.pickupLocation, 
          "dropLocation": inputReq?.dropLocation, 
          "startDate":inputReq?.date, 
          "startTime": inputReq?.time,
          "returnDate": inputReq?.returnDate
      }
      if(inputReq?.emailId){
        payload.email=inputReq?.emailId;
      }
      }else if (selectedType==="Rental"){
        payload={
          "tripMode": selectedType, 
          "name": inputReq?.fullName, 
          "mobileNumber": inputReq?.mobileNumber, 
          "pickupLocation": inputReq?.pickupLocation, 
          "dropLocation": inputReq?.dropLocation, 
          "startDate":inputReq?.date, 
          "startTime": inputReq?.time,
          "rentFor":inputReq?.rentFor
      }
      if(inputReq?.emailId){
        payload.email=inputReq?.emailId;
      }
      }
      setLoading(true)
      const resp=await axios.post("https://droptaxi.online/api/v1/notify",payload)
      setLoading(false)
      if(resp?.data?.message==="Thank you for sharing your details with us! We'll call you back shortly to confirm your booking and assist you further."){  
        message.success(
          "Thank you for sharing your details with us! We'll call you back shortly to confirm your booking and assist you further."
        );
      }
      }catch(e){
        setLoading(false)
        console.log(e)
        message.error("Something unexpected happens,try again later")
      }
    }
  };
  const onValidateMobileNumber = () => {
    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(inputReq?.mobileNumber)) {
      setErrorList({ ...errorList, mobileNumber: "Invalid Mobile Number" });
    }
  };
  const onValidateEmailId = () => {
    if(inputReq?.emailId){
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(inputReq?.emailId)) {
        setErrorList({ ...errorList, emailId: "Invalid Email Id" });
      }
    }
   
  };

  return (
    <div className="container">
      {/* {loading && <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:window.innerHeight,width:"100%",position:"absolute",zIndex:5}} ><Spin  size="large"/></div>} */}
 <div className="header">
 {window.innerWidth<1100 && <div className="image-outer" style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",background:"rgb(210, 210, 210)",height:"50px"}}>
 <a href="tel:7904090598"  title="Phone"><div style={{display:"flex"}}>
               <img
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                  
                  }}
                  src={phone}
                />
                <div style={{ marginLeft: "10px",fontWeight:600 }}>7904090598</div>
                </div></a>
              </div>}
        <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}><div
          style={{
            marginLeft: window?.innerWidth > 1100 ? "130px" : "30px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="logo"
            style={{
              width: "69px",
              height: "61px",
              objectFit: "contain",
              marginTop: "-3px",
            }}
            src={logo1}
          />
          <div
            style={{
              fontWeight: 900,
              marginLeft: "5px",
              fontSize: "23px",
              textDecoration: "overline",
              marginTop: "17px",
            }}
          >
            AGM
          </div>
        </div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"20px"}}>
       {window.innerWidth>1100 &&  <a href="tel:7904090598" style={{color:"black",textDecoration:"none"}} title="Phone"><div className="image-outer" style={{display:"flex",marginRight:"30px"}}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                  src={phone}
                />
                <div style={{ marginLeft: "10px",fontWeight:600 }}>7904090598</div>
              </div></a>}
        <div
          style={{
            marginRight: window?.innerWidth > 1100 ? "100px" : "30px",
            // marginTop: "20px",
          }}
          className="nav-icon"
          onClick={() => setDrawerVisibility(true)}
        >
          <span className="line"></span>
          <span className="second-line"></span>
          <span className="line"></span>
        </div>
        </div>
        </div>
      </div>
      <div className="body-content">
        <div className="main-content" id="homeRef" ref={homeRef}>
          {window.innerWidth > 1100 && (
            <div className="first-part">
              <img alt="mobile" className="mobile" src={mobile}></img>
              {/* <img alt="mobile" className="mobile" src={firstImg}></img> */}
            </div>
          )}
          <div className="second-part">
            {window?.innerWidth > 1100 && (
              <img
                style={{ height: "840px", width: "100%" }}
                src={blueBackground}
              />
            )}
            <div className="form-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="title">GET A TAXI NOW</div>
              </div>
              <div className="tab-bar">
                <div
                  className="tab"
                  style={{
                    marginLeft: "0px",
                    background:
                      selectedType === "One Way" ? "#FFFFFF" : "#332456",
                    color: selectedType === "One Way" ? "#332456" : "#FFFFFF",
                    fontWeight: selectedType === "One Way" ? 700 : 600,
                  }}
                  onClick={() => setSelectedType("One Way")}
                >
                  ONE WAY
                </div>
                <div
                  className="tab"
                  style={{
                    background:
                      selectedType === "Round Trip" ? "#FFFFFF" : "#332456",
                    color: selectedType === "Round Trip" ? "#332456" : "#FFFFFF",
                    fontWeight: selectedType === "Round Trip" ? 700 : 600,
                    padding: "0px 2px",
                  }}
                  onClick={() => setSelectedType("Round Trip")}
                >
                  ROUND TRIP
                </div>
                <div
                  className="tab"
                  style={{
                    background:
                      selectedType === "Rental" ? "#FFFFFF" : "#332456",
                    color: selectedType === "Rental" ? "#332456" : "#FFFFFF",
                    fontWeight: selectedType === "Rental" ? 700 : 600,
                  }}
                  onClick={() => setSelectedType("Rental")}
                >
                  RENTALS
                </div>
              </div>
              <div className="form">
                <Input
                  className="input-field"
                  placeholder="Enter full name"
                  onBlur={() => {
                    if (inputReq?.fullName?.length > 36) {
                      setErrorList({
                        ...errorList,
                        fullName: "Full name can't exceed 36 characters",
                      });
                    }
                  }}
                  style={{
                    marginBottom: errorList?.fullName ? "0px" : "30px",
                  }}
                  onChange={(e) => {
                    setErrorList({ ...errorList, fullName: "" });
                    setInputReq({ ...inputReq, fullName: e.target.value?.trim() || "" });
                  }}
                />
                {errorList?.fullName && (
                  <div
                    style={{
                      color: "#ff6363",
                      fontSize: "12px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                    {errorList?.fullName}
                  </div>
                )}
                <Input
                  className="input-field"
                  placeholder="Enter mobile number"
                  onBlur={() => onValidateMobileNumber()}
                  style={{
                    marginBottom: errorList?.mobileNumber ? "0px" : "30px",
                  }}
                  onChange={(e) => {
                    setErrorList({ ...errorList, mobileNumber: "" });
                    setInputReq({ ...inputReq, mobileNumber: e.target.value?.trim() || ""});
                  }}
                />
                {errorList?.mobileNumber && (
                  <div
                    style={{
                      color: "#ff6363",
                      fontSize: "12px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                    {errorList?.mobileNumber}
                  </div>
                )}
                <Input
                  style={{ marginBottom: errorList?.emailId ? "0px" : "30px" }}
                  className="input-field"
                  placeholder="Enter email ID (Optional)"
                  onBlur={() => onValidateEmailId()}
                  onChange={(e) => {
                    setErrorList({ ...errorList, emailId: "" });
                    setInputReq({ ...inputReq, emailId: e.target.value?.trim() || "" });
                  }}
                />
                {errorList?.emailId && (
                  <div
                    style={{
                      color: "#ff6363",
                      fontSize: "12px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                    {errorList?.emailId}
                  </div>
                )}
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <div>
                      <Input
                        className="input-field"
                        style={{
                          marginBottom: errorList?.pickupLocation
                            ? "0px"
                            : "30px",
                        }}
                        onBlur={() => {
                          if (inputReq?.pickupLocation?.length > 120) {
                            setErrorList({
                              ...errorList,
                              pickupLocation: "Pickup location can't exceed 120 characters",
                            });
                          }
                        }}
                        placeholder="Enter pickup location"
                        onChange={(e) => {
                          setErrorList({ ...errorList, pickupLocation: "" });
                          setInputReq({
                            ...inputReq,
                            pickupLocation: e.target.value?.trim() || "",
                          });
                        }}
                      />
                    </div>
                    {errorList?.pickupLocation && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                        }}
                      >
                        {errorList?.pickupLocation}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                      marginLeft: "20px",
                    }}
                  >
                    <div>
                      <Input
                        className="input-field"
                        // marginLeft: "20px" ,
                        style={{
                          marginBottom: errorList?.dropLocation
                            ? "0px"
                            : "30px",
                        }}
                        onBlur={() => {
                          if (inputReq?.dropLocation?.length > 120) {
                            setErrorList({
                              ...errorList,
                              dropLocation: "Drop location can't exceed 120 characters",
                            });
                          }
                        }}
                        placeholder="Enter drop location"
                        onChange={(e) => {
                          setErrorList({ ...errorList, dropLocation: "" });
                          setInputReq({
                            ...inputReq,
                            dropLocation: e.target.value?.trim() || "",
                          });
                        }}
                      />
                    </div>
                    {errorList?.dropLocation && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                          // marginLeft:"20px"
                        }}
                      >
                        {errorList?.dropLocation}
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <div>
                      <DatePicker
                        style={{
                          marginBottom: errorList?.date ? "0px" : "30px",
                          width: "100%",
                        }}
                        className="date-selector"
                        placeholder="Select date"
                        onChange={(date, dateString) => {
                          setErrorList({ ...errorList, date: "" });
                          setInputReq({ ...inputReq, date: dateString?.trim() || "" });
                        }}
                      />
                    </div>
                    {errorList?.date && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                        }}
                      >
                        {errorList?.date}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                      marginLeft: "20px",
                    }}
                  >
                    <div>
                      <Select
                        className="rent-selector"
                        // marginLeft: "20px",
                        style={{
                          width: "100%",
                          marginBottom: errorList?.time ? "0px" : "30px",
                        }}
                        placeholder="Select time"
                        onChange={(val) => {
                          setErrorList({ ...errorList, time: "" });
                          setInputReq({ ...inputReq, time: val?.trim() || "" });
                        }}
                      >
                        {timeList.map((time) => {
                          return <Option key={time}>{time}</Option>;
                        })}
                      </Select>
                    </div>
                    {errorList?.time && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                          // marginLeft: "20px",
                        }}
                      >
                        {errorList?.time}
                      </div>
                    )}
                  </div>
                </div>
                {selectedType === "Round Trip" && (
                  <>
                    <DatePicker
                      style={{
                        marginBottom: errorList?.returnDate ? "0px" : "30px",
                      }}
                      onChange={(date, dateString) => {
                        setErrorList({ ...errorList, returnDate: "" });
                        setInputReq({ ...inputReq, returnDate: dateString?.trim() || "" });
                      }}
                      className="date-selector"
                      placeholder="Select return date"
                    />
                    {errorList?.returnDate && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                        }}
                      >
                        {errorList?.returnDate}
                      </div>
                    )}
                  </>
                )}
                {selectedType === "Rental" && (
                  <>
                    <Select
                      className="rent-selector"
                      placeholder="Rent for"
                      style={{
                        marginBottom: errorList?.rentFor ? "0px" : "30px",
                      }}
                      onChange={(e) => {
                        setErrorList({ ...errorList, rentFor: "" });
                        setInputReq({ ...inputReq, rentFor: e?.trim() || "" });
                      }}
                    >
                      {rentForList.map((time) => {
                        return <Option key={time}>{time}</Option>;
                      })}
                    </Select>
                    {errorList?.rentFor && (
                      <div
                        style={{
                          color: "#ff6363",
                          fontSize: "12px",
                          marginBottom: "30px",
                          textAlign: "left",
                        }}
                      >
                        {errorList?.rentFor}
                      </div>
                    )}
                  </>
                )}
               <Button className="submit-button" disabled={loading} style={{cursor:loading?"no-drop":"pointer"}} onClick={() => bookNow()}>
                  BOOK NOW
                </Button>
              </div>
            </div>
          </div>
          {window.innerWidth < 1100 && (
            <div className="first-part">
              <img alt="mobile" className="mobile" src={mobile}></img>
            </div>
          )}
        </div>
    
        <div className="tariff-content" id="tariffRef" ref={tariffRef}>
          <div className="services-title">OUR TARIFF</div>
          <div className="cards-section">
            <div className="card">
              <div className="top-part">
                <img className="tariff-image" src={vehicle2} />
              </div>
              <div className="bottom-part">
                <div className="title1">Sedan (Dzire, Etios)</div>
                <div className="title2" style={{ marginBottom: "10px" }}>
                  One Way
                </div>
                <div className="patch-outer">
                  <div className="patch-inner">
                    <div className="title3" style={{ color: "white" }}>
                      ₹ 13
                    </div>
                    <div
                      className="title2"
                      style={{
                        color: "white",
                        textAlign: "center",
                        marginLeft: "6px",
                      }}
                    >
                      Per Km
                    </div>
                  </div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Rs. 12/-</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Driver Allowance - Rs. 400</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">One Way - Minimum 130 Km</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Minimum 250 Km</div>
                </div>
                <Button
                  className="booknow-button"
                  onClick={() => scrollToDiv(homeRef)}
                >
                  BOOK NOW
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="tariff-image" src={vehicle1} />
              </div>
              <div className="bottom-part">
                <div className="title1">SUV (Xylo, Ertiga)</div>
                <div className="title2" style={{ marginBottom: "10px" }}>
                  One Way
                </div>
  
                <div className="patch-outer">
                  <div className="patch-inner">
                    <div className="title3" style={{ color: "white" }}>
                      ₹ 18
                    </div>
                    <div
                      className="title2"
                      style={{
                        color: "white",
                        textAlign: "center",
                        marginLeft: "6px",
                      }}
                    >
                      Per Km
                    </div>
                  </div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Rs. 16/-</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Driver Allowance - Rs. 400</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">One Way - Minimum 130 Km</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Minimum 250 Km</div>
                </div>

                <Button
                  className="booknow-button"
                  onClick={() => scrollToDiv(homeRef)}
                >
                  BOOK NOW
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="tariff-image" src={vehicle} />
              </div>
              <div className="bottom-part">
                <div className="title1">Assured Innova</div>
                <div className="title2" style={{ marginBottom: "10px" }}>
                  One Way
                </div>
                {/* <div className="title3">₹ 13</div> */}
                {/* <div className="title2">Per Km</div> */}
                <div className="patch-outer">
                  <div className="patch-inner">
                    <div className="title3" style={{ color: "white" }}>
                      ₹ 19
                    </div>
                    <div
                      className="title2"
                      style={{
                        color: "white",
                        textAlign: "center",
                        marginLeft: "6px",
                      }}
                    >
                      Per Km
                    </div>
                  </div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Rs. 17/-</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Driver Allowance - Rs. 400</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">One Way - Minimum 130 Km</div>
                </div>
                <div className="tick-outer">
                  <img className="tick-inner" src={greenTick} />
                  <div className="title2">Round Trip - Minimum 250 Km</div>
                </div>
                <Button
                  className="booknow-button"
                  onClick={() => scrollToDiv(homeRef)}
                >
                  BOOK NOW
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="service-content" id="servicesRef" ref={servicesRef}>
          <div className="services-title">OUR SERVICES</div>
          <div className="cards-section">
            <div className="card">
              <div className="top-part">
                <img style={{ width: "100%", height: "100%" }} src={oneWay} />
              </div>
              <div className="bottom-part">
                <div className="heading">ONE WAY TAXI</div>
                <div className="sub-heading">
                  AGM Drop Taxi operates a 24/7 oneway taxi service in Tamil
                  Nadu, Pondicherry and Bangalore. We offer one way trip at one
                  way fare.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="top-part">
                <img style={{ width: "100%", height: "100%" }} src={airport} />
              </div>
              <div className="bottom-part">
                <div className="heading">AIRPORT TAXI</div>
                <div className="sub-heading">
                  {" "}
                  We offer 24/7 Airport Pickup & Drop taxi service in Chennai,
                  Bangalore, Trichy, Coimbatore and Madurai at low cost.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="top-part">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={outstation}
                />
              </div>
              <div className="bottom-part">
                <div className="heading">OUTSTATION TAXI</div>
                <div className="sub-heading">
                  {" "}
                  We operate outstation taxi service across South India at
                  affordable prices. We make our customers travel easy.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="packages-section" ref={packagesRef} id="packagesRef">
          <div className="services-title">OUR PACKAGES</div>
          <div className="image-row">
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={chennai} />
              </div>
              <div className="bottom-part">Chennai</div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={bangalore} />
              </div>
              <div className="bottom-part">Bangalore</div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={pondicherry} />
              </div>
              <div className="bottom-part">Pondicherry</div>
            </div>
          </div>
          <div className="image-row">
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={madurai} />
              </div>
              <div className="bottom-part">Madurai</div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={coimbatore} />
              </div>
              <div className="bottom-part">Coimbatore</div>
            </div>
            <div className="card">
              <div className="top-part">
                <img className="image-div" alt="chennai" src={trichy} />
              </div>
              <div className="bottom-part">Trichy</div>
            </div>
          </div>
        </div>
        <div className="info-section" ref={contactUsRef} id="contactUsRef">
          <div className="info-first">
            {" "}
            <div className="info-outer">
              <div style={{ marginRight: "10px" }}>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={callSupport}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ fontWeight: "700", textAlign: "left" }}>
                  24/7 Customer Support
                </div>
                <div style={{ textAlign: "left" }}>
                  A dedicated 24x7 customer support team always at your service
                  to help solve any problem
                </div>
              </div>
            </div>
            <div className="info-outer">
              <div style={{ display: "flex", width: "300px" }}>
                <div style={{ marginRight: "10px" }}>
                  <img style={{ width: "50px", height: "50px" }} src={safety} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ fontWeight: "700", textAlign: "left" }}>
                    Your Safety First
                  </div>
                  <div style={{ textAlign: "left" }}>
                    Keep your loved ones informed about your travel routes or
                    call emergency services when in need
                  </div>
                </div>
              </div>
            </div>
            <div className="info-outer">
              <div style={{ marginRight: "10px" }}>
                <img style={{ width: "50px", height: "50px" }} src={driver} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ fontWeight: "700", textAlign: "left" }}>
                  Top Rated Driver-Partners
                </div>
                <div style={{ textAlign: "left" }}>
                  All our driver-partners are background verified and trained to
                  deliver only the best experience
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid black",
              width: "40%",
              marginTop: "50px",
            }}
          >
            {" "}
          </div>
        </div>
        <div className="bottom-section">
          <div className="bottom-outer">
            <div className="first-part">
              <div
                style={{
                  // marginLeft: window?.innerWidth > 1100 ? "130px" : "30px",
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="logo"
                  style={{
                    width: "69px",
                    height: "61px",
                    objectFit: "contain",
                    marginTop: "-3px",
                  }}
                  src={logo1}
                />
                <div
                  style={{
                    fontWeight: 900,
                    marginLeft: "5px",
                    fontSize: "23px",
                    textDecoration: "overline",
                    marginTop: "17px",
                    color: "black",
                  }}
                >
                  AGM
                </div>
              </div>
              <div>24/7 Taxi Service</div>
            </div>
            <div className="second-part">
              <div
                style={{
                  fontSize: "24px",
                  color: "#181818",
                  fontWeight: "700",
                }}
              >
                Quick Links
              </div>
              <div className="home" onClick={() => scrollToDiv(homeRef)}>
                Home
              </div>
              <div className="tariff" onClick={() => scrollToDiv(tariffRef)}>
                Tariff
              </div>
              <div
                className="services"
                onClick={() => scrollToDiv(servicesRef)}
              >
                Services
              </div>
              <div
                className="packages"
                onClick={() => scrollToDiv(packagesRef)}
              >
                Packages
              </div>
              <div
                className="contact-us"
                onClick={() => scrollToDiv(contactUsRef)}
              >
                Contact Us
              </div>
            </div>
            <div className="third-part">
              <div
                style={{
                  fontSize: "24px",
                  color: "#181818",
                  fontWeight: "700",
                }}
              >
                Contact Us
              </div>
              <div className="image-outer">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                  src={home}
                />
                <div style={{ marginLeft: "10px", textAlign: "left" }}>
                  Pannaikuchypalayam, Main road, Amul Nagar, Panruti vattam -607
                  102
                </div>
              </div>
              <div className="image-outer">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                  src={phone}
                />
                <div style={{ marginLeft: "10px" }}>+91 7904090598</div>
              </div>
              <div className="image-outer">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                  src={mail}
                />
                <div style={{ marginLeft: "10px" }}>
                customerservice@agmdroptaxi.in
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ marginTop: "20px" }}>
            <img
              style={{ width: "400px", height: "120px" }}
              src={footerCars}
            ></img>
          </div> */}
        </div>
      </div>
      <Drawer
        title="NAVIGATION"
        placement="right"
        onClose={() => setDrawerVisibility(false)}
        open={drawerVisibility}
      >
        <div>
          <div
            style={{
              height: "50px",
              borderBottom: "1px solid #d3d3d3",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onClickNav(homeRef)}
          >
            HOME
          </div>
          <div
            style={{
              height: "50px",
              borderBottom: "1px solid #d3d3d3",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onClickNav(servicesRef)}
          >
            OUR SERVICES
          </div>
          <div
            style={{
              height: "50px",
              borderBottom: "1px solid #d3d3d3",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onClickNav(tariffRef)}
          >
            OUR TARIFF
          </div>
          <div
            style={{
              height: "50px",
              borderBottom: "1px solid #d3d3d3",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onClickNav(packagesRef)}
          >
            OUR PACKAGES
          </div>
          <div
            style={{
              height: "50px",
              borderBottom: "1px solid #d3d3d3",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onClickNav(contactUsRef)}
          >
            CONTACT US
          </div>
        </div>
      </Drawer>

      {/* <div className="footer"> */}
      <div
        style={{ position: "fixed", left: 4, bottom: 10, height: "60px" ,marginLeft:"10px"}}
        className="footer-first"
        onMouseEnter={() => setOpenWhatsapp(true)}
        onMouseLeave={() => setOpenWhatsapp(false)}
      >
        <a href="https://wa.me/+917904090598" title="Lets chat!">
          <Popover
            open={openWhatsapp}
            content="Le ts chat!"
            style={{ background: "black", color: "white" }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              className="whatsapp"
              src={whatsappIcon}
            />
          </Popover>
        </a>
      </div>
      <div
        className="footer-second"
        style={{
          marginRight: "10px",
          position: "fixed",
          right: 4,
          bottom: 10,
          height: "60px",
        }}
        onMouseEnter={() => setOpenPhone(true)}
        onMouseLeave={() => setOpenPhone(false)}
      >
        <a href="tel:7904090598" title="Phone">
          <Popover content="Phone" open={openPhone}>
            <img
              style={{ width: "100%", height: "100%" }}
              className="phonelogo"
              src={phonelogo}
            />
          </Popover>
        </a>
      </div>
      
    </div>
  );
};
